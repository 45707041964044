<template>
<span>

    <v-toolbar flat color="white">
        <v-toolbar-title >
            <img v-if="productsubcategory.prod_sc_icone" :src="`${base_url}upload/product_subcategory/i/${productsubcategory.prod_sc_icone}`" width="40">
            <img v-if="!productsubcategory.prod_sc_icone" :src="`${base_url}upload/imagem.png`" width="40">
        </v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
        <h1 class="lime--text lighten-1">{{ productsubcategory.prod_sc_nome }}</h1>
        <v-spacer></v-spacer>
        <template>
            <v-btn color="blue-grey" dark class="mb-2" :to="{name: 'product-subcategories'}">
                <v-icon dark>keyboard_arrow_left</v-icon> Voltar
            </v-btn>
        </template>
    </v-toolbar>

    <table id="table-detalhe" class="table table-bordered table-condensed">
        <tbody>
            <tr>
                <th width="10%">Código:</th>
                <td width="90%">{{ productsubcategory.prod_sc_codigo }}</td>
            </tr>
            <tr>
                <th width="10%">Nome:</th>
                <td width="90%">{{ productsubcategory.prod_sc_nome }}</td>
            </tr>
            <tr v-if="productsubcategory.categoria">
                <th width="10%">Categoria:</th>
                <td width="90%">{{ productsubcategory.categoria.prod_c_nome }}</td>
            </tr>
            <tr>
                <th width="10%">Link:</th>
                <td width="90%">{{ productsubcategory.prod_sc_link }}</td>
            </tr>
            <tr>
                <th width="10%">Ordem:</th>
                <td width="90%">{{ productsubcategory.prod_sc_ordem }}</td>
            </tr>
            <tr>
                <th width="10%">Status:</th>
                <td width="90%">{{ (productsubcategory.prod_sc_status == '1') ? 'Ativo' : 'Inativo' }}</td>
            </tr>
            <tr>
                <th width="10%">Mostrar no menu:</th>
                <td width="90%">{{ (productsubcategory.prod_sc_show_menu == '1') ? 'Ativo' : 'Inativo' }}</td>
            </tr>
            <tr>
                <th width="10%">Icone:</th>
                <td v-if="productsubcategory.prod_sc_icone" width="10%">
                    <img :src="`${base_url}upload/product_subcategory/i/${productsubcategory.prod_sc_icone}`" width="60">
                </td>
                <td v-if="!productsubcategory.prod_sc_icone" width="10%">
                    <img :src="`${base_url}upload/imagem.png`">
                </td>
            </tr>
            <tr>
                <th width="10%">Banner:</th>
                <td v-if="productsubcategory.prod_sc_banner" width="10%">
                    <img :src="`${base_url}upload/product_subcategory/b/${productsubcategory.prod_sc_banner}`" width="400">
                </td>
                <td v-if="!productsubcategory.prod_sc_banner" width="10%">
                    <img :src="`${base_url}upload/imagem.png`">
                </td>
            </tr>
        </tbody>
    </table>

</span>
</template>

<script>
import {
    URL_BASE
} from '../../../config/configs'

export default {
    name: "DetailEmployeeComponent",
    created() {
        this.loadProductSubcategory()
    },
    props: {
        prod_sc_id: {
            require: true
        },
    },
    data() {
        return {
            search: '',
            productsubcategory: {},
            base_url: URL_BASE
        }
    },
    methods: {
        loadProductSubcategory() {
            this.$store.dispatch('loadProductSubcategory', this.prod_sc_id)
                .then(response => {
                    this.productsubcategory = response
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: 'Erro',
                        text: 'Dados não localizado',
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
        },
    }
};
</script>

<style scoped>
.avatar {
    height: 25px;
}

.logo {
    height: 50px;
}
</style>
